import { IForm } from '@/base-ui/form/types'

export const modalConfig: IForm = {
  title: '添加角色',
  labelWidth: '100px',
  formItems: [
    {
      field: 'name',
      type: 'input',
      label: '角色名',
      placeholder: '请输入角色名'
    },
    {
      field: 'desc',
      type: 'input',
      label: '角色介绍',
      placeholder: '请输入角色介绍'
    }
  ],
  colLayout: {
    span: 24
  }
}
