import useSystemStore from '@/store/modules/system'
export function usePageDel() {
  const systemStore = useSystemStore()
  const handleDelBtnClick = (item: any, pageName: string) => {
    systemStore.fetchPageDel({
      pageName,
      id: item.id
    })
  }
  return [handleDelBtnClick]
}
