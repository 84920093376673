export const contentTableConfig = {
  addBtnTxt: '添加角色',
  propList: [
    { prop: 'name', label: '角色名' },
    { prop: 'desc', label: '描述' },
    {
      prop: 'create_time',
      label: '创建时间'
    },
    { label: '操作', slotName: 'handler' }
  ]
}
